import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Configs  from '../app.constants';
import { map } from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable()
export class UserService{
    constructor(private http: HttpClient){}
    getUsage(){
        return this.http.get(Configs.BASE_PATH + 'user/usage' +'?ts='+ Date.now());
    };
    getUserAccountDetailsById(){
        return this.http.get(Configs.BASE_PATH + 'user/accountDetails' +'?ts='+ Date.now());
    };
    getUserInvoices(){
        return this.http.get(Configs.BASE_PATH + 'user/invoices' +'?ts='+ Date.now());
    };
    updatePaymentInfo(payload : any){
        return this.http.put(Configs.BASE_PATH + 'payment/update-cc', payload);
    };
    getTaxRate(query:string){
        return this.http.get(Configs.BASE_PATH + 'getTaxRates' + query + '&ts='+ Date.now());
    };
    searchMakeModel(term:string){
        if (term.length < 4) {
            return of([]);
        }
        else{
            return this.http.get(Configs.BASE_PATH + 'getMakeModel?term=' + term).pipe(
                map(response => response)
            );
        }
    };
    getGeoTaxBatch(payload : any){
        return this.http.post(Configs.BASE_PATH + 'getBatchTaxRates', payload);
    };
    verifyAddress(payload : any){
        return this.http.post(Configs.BASE_PATH + 'verifyAddress', payload);
    };
    getUserStatus = function () {
        return this.http.get(Configs.BASE_PATH + 'users/status');
    };
    cancelSubscription(){
        return this.http.get(Configs.BASE_PATH + 'user/cancelSubscription');
    };
    activateSubscription(){
        return this.http.get(Configs.BASE_PATH + 'user/activateSubscription');
    };
    getResources(){
        return this.http.get('/api/getresources');
    };
    getUsersBySubscriptionId(){
        return this.http.get(Configs.BASE_PATH + 'users/subscription');
    };
    getUserByUserId(userId : string){
        return this.http.get(Configs.BASE_PATH + 'user/' + userId);
    };
    addToSubscription(payload : any){
        return this.http.post(Configs.BASE_PATH + 'user/subscription', payload);
    };
    createUser(user : any){
        return this.http.post(Configs.BASE_PATH + 'user/create', user);
    };
    deleteUser(userId : string){
        return this.http.delete(Configs.BASE_PATH + 'user/subscription/user/'+userId);
    };   
    getUserDeatails(){
        return this.http.get('/api/userDetails');
    };
}
