import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../service/user.service';


@Component({
    selector: 'app-header',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
    public displayName: string = '';
    public showSignInLink: boolean = true;
    public userRole: string = '';
    constructor(private userService: UserService) { };

    ngOnInit() {
        let userDetails = null;
        this.userService.getUserDeatails().subscribe(
            success => {
                userDetails = success;
                this.showSignInLink = false;
                this.displayName = userDetails.firstName + " " + userDetails.lastName;
                this.userRole = userDetails.entitlements[0].role;
            },
            error => {
                this.displayName = 'Sign In';
            }
        );
    };
}
