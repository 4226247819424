import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  // add the first date of the copyright
  // if it's the current year, you will get a single © date
  // if it's earlier, you get a date range ending in the current year
  private startYear = 2020;
  public date: string;

  constructor() {}

  ngOnInit() {
    const currentYear: number = new Date().getFullYear();
    this.date = this.startYear === currentYear ? `${currentYear}` : `${this.startYear},${currentYear}`;
    //this.date = `${currentYear}`;
  }
}
