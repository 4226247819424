import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/user.service';
declare const recurly: any;
import * as $ from 'jquery';

@Component({
    selector: 'app-billingplans',
    templateUrl: './billingplans.component.html',
    styleUrls: ['./billingplans.component.css']
})
export class BillingplansComponent implements OnInit {
  public company = '';
  public profileFirstName = '';
  public profileLastName = '';
  public email = '';
  public showLoader: boolean = false;
  public quotaAvailable: string = "";
  public totalPointsUsed: string = "";
  public totalPointsUsedText: string = "";
  public overageCharge: string = "";
  public subscriptionState: string = "";
  public entitlementsStatus: string = "";
  public userExpiredMsg: string = "";
  public planId: string = "";
  public planName: string = "";
  public showupgradelink: boolean = false;
  public hasPastDue: boolean = true;
  public hasFutSubs: boolean = true;
  public isCancelledEntitlement: boolean = false;
  public isSuspendedEntitlement: boolean = false;
  @ViewChild('cancelConfirmModal', { static: false }) cancelConfirmModalEl: ElementRef;
  public cancelConfirmModalRef: any;
  public isCancelSubsErr: boolean = false;
  public isCancelSubsSuccess: boolean = false;
  public isCancelProcessing: boolean = false;
  public isSuspenProcessing: boolean = false;
  public isSuspenSubsSuccess: boolean = false;
  public isSuspenSubsErr: boolean = false;
  private upgradablePlanIdArr = ['GT_General_Auto_Free_10_14Day', 'GT_General_Auto_Basic_Monthly', 'GT_General_Auto_100_Monthly'];
  private planObj = {
    GT_General_Auto_Free_10_14Day: 'Free Trial',
    GT_General_Auto_Basic_Monthly: 'GeoTax 15 Monthly',
    GT_General_Auto_100_Monthly: 'GeoTax 100 Monthly',
    GT_General_Auto_100_Annual: 'GeoTax 100 Annual'
  };
  public stateMap = {
    AL: "Alabama", AK: "Alaska", AZ: "Arizona", AR: "Arkansas", CA: "California", CO: "Colorado", CT: "Connecticut", DE: "Delaware", DC: "District Of Columbia", FL: "Florida", GA: "Georgia", HI: "Hawaii", ID: "Idaho", IL: "Illinois", IN: "Indiana", IA: "Iowa", KS: "Kansas", KY: "Kentucky", LA: "Louisiana", ME: "Maine", MD: "Maryland", MA: "Massachusetts", MI: "Michigan", MN: "Minnesota", MS: "Mississippi", MO: "Missouri", MT: "Montana", NE: "Nebraska", NV: "Nevada", NH: "New Hampshire", NJ: "New Jersey", NM: "New Mexico", NY: "New York", NC: "North Carolina", ND: "North Dakota", OH: "Ohio", OK: "Oklahoma", OR: "Oregon", PA: "Pennsylvania", RI: "Rhode Island", SC: "South Carolina", SD: "South Dakota", TN: "Tennessee", TX: "Texas", UT: "Utah", VT: "Vermont", VA: "Virginia", WA: "Washington", WV: "West Virginia", WI: "Wisconsin", WY: "Wyoming"
  };
  public invoiceArr = [];
  public noInvoiceflag: boolean = false;
  public isPaymentErr: boolean = false;
  public isSubsActiveErr: boolean = false;
  public isPaymentSuccess: boolean = false;
  public paymentErrMsg: string = '';
  public showCCForm: boolean = false;
  public cardNumber: string = '';
  public expirationDate: string = '';
  public displayName: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public cardType: string = '';
  public addressLine1: string = '';
  public addressLine2:string = '';
  public city: string = '';
  public stateprovince: string = '';
  public stateName: string = '';
  public postalCode: string = '';
  public showConfirmAddr:boolean = false;
  public verifiedAddrRes:any;
  public confirmAddrRadio:any = null;
  public showpage:boolean = false;
  public userRole: string = '';
  public userData: any = null;
  constructor(private router : Router, private userService: UserService, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  };
  ngOnInit() {
    this.userService.getResources().subscribe(
      success => {
        localStorage.setItem("recurlyConfig", success['recurlyConfig']);
      }
    );
    this.userService.getUserDeatails().subscribe(
      success => {
        this.userData = success;
        this.userRole = this.userData.entitlements[0].role;
        if(this.userRole == 'AdminWithBilling' || this.userRole == 'FinanceAdmin'){
          this.showpage = true;
        }
        else{
          window.location.href = "/geotax";
        }
        if(this.userData.subscriptions.geotax){
          this.subscriptionState = this.userData.subscriptions.geotax.subscriptionState;
          if(this.subscriptionState != 'active'){
            this.userExpiredMsg = 'Subscription Expired';
          }
        }
        if(this.userData.entitlements.length>0){
          this.profileFirstName = this.userData.firstName;
          this.profileLastName = this.userData.lastName;
          this.email = this.userData.email;
          if(this.userData.entitlements[0].product === "geotax"){
            this.company = this.userData.entitlements[0].companyName;
            this.planId = this.userData.entitlements[0].plan;
            if(this.upgradablePlanIdArr.includes(this.planId)){
              this.showupgradelink = true;
            }
            this.planName = this.planId;
            if(this.planId in this.planObj){
              this.planName = this.planObj[this.planId];
            }
            else if(this.planId.includes('Free')){
              this.planName = 'Free Trial';
              this.showupgradelink = true;
            }
            this.entitlementsStatus = this.userData.entitlements[0].status.toLowerCase();
            if(this.entitlementsStatus == 'cancelled'){
              this.isCancelledEntitlement = true;
              this.userExpiredMsg = 'Subscription Cancelled';
            }
            if(this.entitlementsStatus == 'suspended'){
              this.isSuspendedEntitlement = true;
              this.userExpiredMsg = 'Subscription Suspended';
            }
            let recai = this.userData.entitlements[0].recai;
            if(recai){
              if (this.planId == 'GT_General_Auto_Basic_Monthly' || this.planId == 'GT_General_Auto_100_Monthly' || this.planId == 'GT_General_Auto_100_Annual') {
                this.getUserAccountDetails();
              }
              else{
                this.hasPastDue = false;
                this.hasFutSubs = false;
              }
            }
            else{
              alert("User without recurly account id.");
            }
          }
        }
        this.getUserUsage();
        this.getUserInvoices();
      },
      error => {
        window.location.href = "/";
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
          if (success.data[0].billing_info != null) {
            this.cardNumber = '**** **** **** '+success.data[0].billing_info.payment_method.last_four;
            this.expirationDate = success.data[0].billing_info.payment_method.exp_month +'/'+ success.data[0].billing_info.payment_method.exp_year;
            this.firstName = success.data[0].billing_info.first_name;
            this.lastName = success.data[0].billing_info.last_name;
            this.displayName = success.data[0].billing_info.first_name + ' ' +success.data[0].billing_info.last_name;
            this.cardType = success.data[0].billing_info.payment_method.card_type.toUpperCase();
            this.addressLine1 = success.data[0].billing_info.address.street1;
            this.city = success.data[0].billing_info.address.city;
            this.stateprovince = success.data[0].billing_info.address.region;
            this.stateName = this.stateMap[this.stateprovince];
            this.postalCode = success.data[0].billing_info.address.postal_code;
          }
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  checkPastDue = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  getUserUsage = function(){
    this.showLoader = true;
    this.userService.getUsage().subscribe(
      success => {
        this.quotaAvailable = this.numberWithCommas(success.quota) + " transactions";
        this.totalPointsUsedText = "searches used";
        this.totalPointsUsed = this.numberWithCommas(Math.round(success.totalPointsUsed));
        if(!this.planId.includes('Free')){
          this.quotaAvailable += "/mo.";
          this.totalPointsUsedText += " this month";
        }
        if(this.userData.entitlements.length>0){
          if(this.userData.entitlements[0].product === "geotax"){
            let pbPlanId = this.userData.entitlements[0].plan;
            if(pbPlanId.toLowerCase().includes('free')){
              this.overageCharge = "";
            }
            else if(pbPlanId.toLowerCase().includes('basic')){
              this.overageCharge = "$0.67";
            }
            else if(pbPlanId.toLowerCase().includes('monthly')){
              this.overageCharge = "$0.50";
            }
            else if(pbPlanId.toLowerCase().includes('annual')){
              this.overageCharge = "$0.45";
            }
          }
        }
        this.showLoader = false;
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  numberWithCommas = function(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  getUserInvoices = function(){
      this.userService.getUserInvoices().subscribe(
          success => {
              if(success.data.length == 0){
                  this.noInvoiceflag = true;
              }
              else {
                  for(let i=0; i<success.data.length; i++){
                    if(success.data[i].total !== 0){
                      let invoiceObj = {};
                      invoiceObj['state'] = success.data[i].state;
                      invoiceObj['id'] = success.data[i].id;
                      invoiceObj['number'] = success.data[i].number;
                      invoiceObj['created_at'] = this.formatUSDateStr(success.data[i].created_at);
                      if(success.data[i].closed_at){
                        invoiceObj['closed_at'] = this.formatUSDateStr(success.data[i].closed_at);
                      }
                      else{
                        invoiceObj['closed_at'] = 'N/A';
                      }
                      invoiceObj['total'] = this.formatCurrency(success.data[i].currency, success.data[i].total);
                      invoiceObj['paid'] = this.formatCurrency(success.data[i].currency, success.data[i].paid);
                      this.invoiceArr.push(invoiceObj);
                    }
                  }
                  this.invoiceArr.sort(function (a, b) {
                    return b.number - a.number;
                  });
                  if(this.invoiceArr.length == 0){
                    this.noInvoiceflag = true;
                  }
              }
          },
          error => {
            if(error.error.error == 'Cookie tempered'){
              window.location.href = '/signout';
            }
          }
      );
  };
  formatUSDateStr = function(dateStr){
    let dateObj = new Date(dateStr);
    return (dateObj.getMonth()+1)+'-'+dateObj.getDate()+'-'+dateObj.getFullYear();
  };
  formatCurrency = function(currency, amount){
    let amountStr = String(amount);
    if(currency == 'USD'){
      amountStr = '$'+amountStr;
    }
    let amountArr = amountStr.split('.');
    if(amountArr.length == 1){
      amountStr += '.00';
    }
    else if(amountArr[1].length > 2){
      amountStr = amountArr[0]+'.'+amountArr[1].substring(0, 2);
    }
    return amountStr;
  };
  showInvoice = function(invoiceNo) {
	  document.getElementById(invoiceNo).setAttribute("disabled", "disabled");
    var reqUrl = '/v1/geotax/user/invoice/' +invoiceNo;
    var req = new XMLHttpRequest();
    req.open("GET", reqUrl, true);
    req.responseType = "blob";
    req.onload = function (event) {
      if(req.status !== 403){
        var blob = req.response;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "GeoTAX_Invoice_" +invoiceNo+ ".pdf";
        link.click();
        document.getElementById(invoiceNo).removeAttribute("disabled");
      }
      else{
        window.location.href = '/signout';
      }
    };
    req.send();
  };
  showPaymentForm = function(){
    this.showCCForm = true;
    this.isSubsActiveErr = false;
    let cardEleHtml = $('#recurly-elements').html();
    if(cardEleHtml !== ''){
      $('#recurly-elements').html('');
    }
    $('#recurly-form').show();
    recurly.configure({
      publicKey: localStorage.getItem("recurlyConfig"),
      required: [ 'cvv' ]
    });
    const elements = recurly.Elements();
    const cardElement = elements.CardElement({​
      inputType: 'mobileSelect',
      style: {​
        fontSize: '1em',
        padding: '10px 12px',
        height: '40px',
        placeholder: {​
          color: 'gray !important',
          fontWeight: 'bold',
          content: {​
            number: 'Card number',
            cvv: 'CVV'
          }​
        }​,
        invalid: {​
          fontColor: 'red'
        }​
      }​
    }​);
    cardElement.attach('#recurly-elements');
    let fields = {​
      'first_name': 'First Name',
      'last_name': 'Last Name',
      'address1': 'Address',
      'address2': 'Address 2',
      'city': 'City',
      'state': 'State',
      'postal_code': 'Postal Code',
      'number': 'Credit Card Number',
      'month': 'Credit Card Month',
      'year': 'Credit Card Year',
      'cvv': 'CVV'
    }​;
    let that = this;
    document.querySelector('#recurly-form').addEventListener('submit', function(event){
      const form = this;
      event.preventDefault();​
      if(that.confirmAddrRadio == null){
        that.paymentErrMsg = 'Please select a corrected address.';
        that.isPaymentErr = true;
        document.getElementById("submitPaymentBtn").removeAttribute("disabled");
        document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
        document.querySelector("#paymentNotification").scrollIntoView();
        return false;
      }
      document.getElementById("submitPaymentBtn").setAttribute("disabled", "disabled");
      document.getElementById("reEnterAddrBtn").setAttribute("disabled", "disabled");
      that.paymentErrMsg = '';
      that.isPaymentErr = false;
      recurly.token(elements, form, function(err, token){
        if(err){
          let failedMsgArr = [];
          if(err.name === 'api-error' || err.name === 'validation'){
            for (let i = 0; i < err.details.length; i++) {​
              failedMsgArr.push(`${​fields[err.details[i].field]}​ ${​err.details[i].messages[0]}​`);
            }​
          }​
          else {​
            failedMsgArr.push(err.message);
          }
          for (let i = 0; i < failedMsgArr.length; i++) {
            if(i !== 0){
              if(i === failedMsgArr.length -1){
                that.paymentErrMsg += ' and ';
              }
              else{
                that.paymentErrMsg += ', ';
              }
            }
            that.paymentErrMsg += failedMsgArr[i];
          }​
          that.paymentErrMsg += '.';
          that.isPaymentErr = true;
          document.getElementById("submitPaymentBtn").removeAttribute("disabled");
          document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
          document.querySelector("#paymentNotification").scrollIntoView();
        ​}
        else{
          let payload = {
            token_id : token.id
          };
          that.userService.updatePaymentInfo(payload).subscribe(
            success => {
              that.isPaymentSuccess = true;
              that.isPaymentErr = false;
              document.querySelector("#paymentNotification").scrollIntoView();
              if(that.hasPastDue || that.hasFutSubs){
                that.checkPastDue();
              }
              that.cardNumber = '**** **** **** '+success.payment_method.last_four;
              that.expirationDate = success.payment_method.exp_month +'/'+ success.payment_method.exp_year;
              that.firstName = success.first_name;
              that.lastName = success.last_name;
              that.displayName = success.first_name + ' ' +success.last_name;
              that.cardType = success.payment_method.card_type.toUpperCase();
              that.addressLine1 = success.address.street1;
              that.addressLine2 = success.address.street2;
              that.city = success.address.city;
              that.stateprovince = success.address.region;
              that.stateName = that.stateMap[that.stateprovince];
              that.postalCode = success.address.postal_code;
              setTimeout(() => {
                document.getElementById("submitPaymentBtn").removeAttribute("disabled");
                document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
                that.closePaymentForm(false);
              }, 3000);
            },
            error => {
              if(error.error.error == 'Cookie tempered'){
                window.location.href = '/signout';
                return;
              }
              document.getElementById("submitPaymentBtn").removeAttribute("disabled");
              document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
              that.paymentErrMsg = 'Failed to update. Please try again.';
              if(error.error){
                that.paymentErrMsg = error.error.errorMessage.error.message;
              }
              that.isPaymentErr = true;
              document.querySelector("#paymentNotification").scrollIntoView();
            }
          );
        }
      });​
    });
  };
  closePaymentForm = function(isPaymentCancel){
    this.showCCForm = false;
    this.isPaymentErr = false;
    this.isPaymentSuccess = false;
    $('#recurly-form').hide();
    this.showConfirmAddr = false;
    this.confirmAddrRadio = null;
    $('#validateAddressBtn').show();
    $('#cancelPaymentBtn').show();
    $('#submitPaymentBtn').hide();
    $('#reEnterAddrBtn').hide();
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
          if (success.data[0].billing_info != null) {
            this.cardNumber = '**** **** **** '+success.data[0].billing_info.payment_method.last_four;
            this.expirationDate = success.data[0].billing_info.payment_method.exp_month +'/'+ success.data[0].billing_info.payment_method.exp_year;
            this.firstName = success.data[0].billing_info.first_name;
            this.lastName = success.data[0].billing_info.last_name;
            this.displayName = success.data[0].billing_info.first_name + ' ' +success.data[0].billing_info.last_name;
            this.cardType = success.data[0].billing_info.payment_method.card_type.toUpperCase();
            this.addressLine1 = success.data[0].billing_info.address.street1;
            this.city = success.data[0].billing_info.address.city;
            this.stateprovince = success.data[0].billing_info.address.region;
            this.stateName = this.stateMap[this.stateprovince];
            this.postalCode = success.data[0].billing_info.address.postal_code;
            if(!isPaymentCancel){
              this.updateUserStatusInformation();
            }
          }
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  validateAddress = function(){
    let errArr = [];
    if(this.firstName == ''){
      errArr.push("First Name​ can't be blank​");
    }
    if(this.lastName == ''){
      errArr.push("Last Name​ can't be blank​");
    }
    if(this.addressLine1 == ''){
      errArr.push("Address​ can't be blank​");
    }
    if(this.postalCode == ''){
      errArr.push("Postal Code can't be blank​");
    }
    else{
      let usZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/; //regex for USA zip only
      if(!usZipRegex.test(this.postalCode)){
        errArr.push("Postal Code is invalid​​");
      }
    }
    if(this.city == ''){
      errArr.push("City​ can't be blank​");
    }
    if(errArr.length){
      this.showConfirmAddr = false;
      this.paymentErrMsg = "";
      for (let i = 0; i < errArr.length; i++) {
        if(i !== 0){
          if(i === errArr.length -1){
            this.paymentErrMsg += ' and ';
          }
          else{
            this.paymentErrMsg += ', ';
          }
        }
        this.paymentErrMsg += errArr[i];
      }​
      this.paymentErrMsg += "."
      this.isPaymentErr = true;
      document.querySelector("#paymentNotification").scrollIntoView();
    }
    else{
      this.isPaymentErr = false;
      this.showConfirmAddr = false;
      document.getElementById("validateAddressBtn").setAttribute("disabled", "disabled");
      document.getElementById("cancelPaymentBtn").setAttribute("disabled", "disabled");
      let addrPayload = {
        "AddressLine1": this.addressLine1,
        "AddressLine2": this.addressLine2,
        "City": this.city,
        "Country": "USA",
        "StateProvince": this.stateprovince,
        "PostalCode": this.postalCode
      };
      this.userService.verifyAddress(addrPayload).subscribe(
        success => {
          if(success.Output.length > 0){
            if(success.Output.length == 1){
              let addConfidence = success.Output[0].Confidence;
              if(addConfidence < 80){
                this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
                this.isPaymentErr = true;
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
                document.querySelector("#paymentNotification").scrollIntoView();
              }
              else{
                $('#validateAddressBtn').hide();
                $('#cancelPaymentBtn').hide();
                $('#submitPaymentBtn').show();
                $('#reEnterAddrBtn').show();
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
                this.verifiedAddrRes = success.Output;
                this.showConfirmAddr = true;
              }
            }
            else{
              $('#validateAddressBtn').hide();
              $('#cancelPaymentBtn').hide();
              $('#submitPaymentBtn').show();
              $('#reEnterAddrBtn').show();
              document.getElementById("validateAddressBtn").removeAttribute("disabled");
              document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
              this.verifiedAddrRes = success.Output;
              this.showConfirmAddr = true;
            }
          }
          else{
            this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
            this.isPaymentErr = true;
            document.getElementById("validateAddressBtn").removeAttribute("disabled");
            document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
            document.querySelector("#paymentNotification").scrollIntoView();
          }
        },
        error => {
          if(error.error.error == 'Cookie tempered'){
            window.location.href = '/signout';
          }
        }
      );
    }
  };
  selectConfirmedAddress = function(){
    let address = this.verifiedAddrRes[this.confirmAddrRadio];
    this.addressLine1 = address.AddressLine1;
    this.addressLine2 = address.AddressLine2;
    this.city = address.City;
    this.stateprovince = address.StateProvince;
    this.postalCode = address.PostalCode;
  };
  resetAddressFields = function(){
    this.confirmAddrRadio = null;
    $('#validateAddressBtn').show();
    $('#cancelPaymentBtn').show();
    $('#submitPaymentBtn').hide();
    $('#reEnterAddrBtn').hide();
  };
  updateUserStatusInformation = function(){
    for (let i = 0; i < this.userData.entitlements.length; i++) {
      if (this.userData.entitlements[i].product == 'geotax') {
        if(this.userData.entitlements[i].status == 'SUSPENDED'){
          if(this.hasPastDue){
            this.isSubsActiveErr = true;
            return;
          }
          this.userService.activateSubscription().subscribe(
            success => {
              this.userService.getUserStatus().subscribe(
                success => {
                  window.location.reload();
                }
              );
            },
            error => {
              if(error.error.error == 'Cookie tempered'){
                window.location.href = '/signout';
                return;
              }
              this.isSubsActiveErr = true;
            }
          );
        }
      }
    }
  };
  cancelSubscription = function(){
    setTimeout(() =>
      this.cancelConfirmModalRef = this.modalService.open(this.cancelConfirmModalEl, { ariaLabelledBy: 'modal-basic-title'})
    );
  };
  confirmCancelSubscription = function(){
    this.isCancelProcessing = true;
    this.isCancelSubsSuccess = false;
    this.isCancelSubsErr = false;
    this.userService.cancelSubscription().subscribe(
      success => {
        this.isCancelProcessing = false;
        this.isCancelSubsSuccess = true;
        this.userService.getUserStatus().subscribe(
          success => {
            window.location.reload();
          }
        );
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
          return;
        }
        this.isCancelSubsErr = true;
      }
    );
  };
  activateSubscription = function(){
    this.isSuspenSubsSuccess = false;
    this.isSuspenSubsErr = false;
    if(this.hasPastDue){
      this.isSuspenSubsErr = true;
      window.scroll(0, 0);
      return;
    }
    this.isSuspenProcessing = true;
    this.userService.activateSubscription().subscribe(
      success => {
        this.isSuspenSubsSuccess = true;
        window.scroll(0, 0);
        this.userService.getUserStatus().subscribe(
          success => {
            window.location.reload();
          }
        );
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
          return;
        }
        this.isSuspenProcessing = false;
        this.isSuspenSubsErr = true;
        window.scroll(0, 0);
      }
    );
  };
}
