import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-createuser',
    templateUrl: './createuser.component.html',
    providers: [NgbModalConfig, NgbModal]
})
export class CreateuserComponent implements OnInit{
    @ViewChild('accountStatusModal', { static: false }) accountStatusModalEl: ElementRef;
    @ViewChild('accountStatusModal1', { static: false }) accountStatusModalEl1: ElementRef;
    public showpage: boolean = false;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public access: string = 'User';
    public errMsg: string = '';
    public isCreateSuccess: boolean = false;
    public userRole: string = '';
    public showLoader: boolean = false;
    public isUserExist: boolean = false;
    public boldEmail: string = '';
    public userRoles: any = ['Admin', 'User'];
    public entitlementsStatus: string;
    constructor(private router: Router, private userService: UserService, config: NgbModalConfig, private modalService: NgbModal){
        config.backdrop = 'static';
        config.keyboard = false;
    }
    ngOnInit(){
        let userData = null;
        this.userService.getUserDeatails().subscribe(
            success => {
                userData = success;
                this.userRole = userData.entitlements[0].role;
                this.entitlementsStatus = userData.entitlements[0].status.toLowerCase();
                if(this.userRole == 'AdminWithBilling' || this.userRole == 'Admin' || this.userRole == 'FinanceAdmin'){
                    this.showpage = true;
                    if(this.userRole == 'AdminWithBilling' || this.userRole == 'FinanceAdmin'){
                        this.userRoles.push('FinanceAdmin');
                        this.userRoles.sort();
                    }
                }
                else{
                    window.location.href = "/geotax";
                }
            },
            error => {
              window.location.href = "/";
            }
        );
    }
    createNewUser = function(){
        this.isCreateSuccess = false;
        this.isUserExist = false;
        this.errMsg = '';
        if(this.firstName === ''){
            this.errMsg = "First name can't be blank.";
            return;
        }
        if(this.lastName === ''){
            this.errMsg = "Last name can't be blank.";
            return;
        }
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(this.firstName)){
            this.errMsg = "First name can't contain special characters.";
          return;
        }
        if(format.test(this.lastName)){
            this.errMsg = "Last name can't contain special characters.";
            return;
        }
        if(this.email === ''){
            this.errMsg = "Email can't be blank.";
            return;
        }
        if(!this.validateEmail()){
            this.errMsg = "Email is not valid.";
            return;
        }
        if(this.entitlementsStatus == 'cancelled') {
            setTimeout(() =>
                this.modalService.open(this.accountStatusModalEl, { size: 'md', ariaLabelledBy: 'modal-basic-title' })
            );
            return;
        }
        if(this.entitlementsStatus == 'suspended') {
          setTimeout(() =>
              this.modalService.open(this.accountStatusModalEl1, { size: 'md', ariaLabelledBy: 'modal-basic-title' })
          );
          return;
      }
        if(this.firstName !== '' && this.lastName !== '' && this.email !== '' && this.access !== '' && this.validateEmail()){
            if(this.access == 'FinanceAdmin'){
                this.showLoader = true;
                this.userService.getUsersBySubscriptionId().subscribe(
                    success => {
                        let finAdminCount = 0;
                        let usersObj = success['items'];
                        for(let i=0; i<usersObj.length; i++){
                            if(usersObj[i].membershipData.roles[0] == 'FinanceAdmin'){
                                finAdminCount++;
                            }
                        }
                        if(finAdminCount < 3){
                            this.createUserApi();
                        }
                        else{
                            this.showLoader = false;
                            this.errMsg = "Your subscription already has 3 Finance Admin role users, which is the maximum limit.";
                            return;
                        }
                    }
                );
            }
            else{
                this.createUserApi();
            }
        }
    };
    createUserApi = function(){
        let userBody = {
            "profile":{
                "firstName":this.firstName,
                "lastName":this.lastName,
                "email":this.email,
                "login":this.email,
                "pbAdministrator":true,
                "roles": [this.access]
            }
        };
        this.showLoader = true;
        let subscriptionPayload = {
            "membershipData": {
                "membershipStatus": "ACTIVE",
                "roles": [this.access]
            },
            "user": {
                "id": this.email,
                "firstName": this.firstName,
                "lastName": this.lastName,
                "isUserExisting": true
            }
        };
        this.userService.getUserByUserId(this.email).subscribe(
            success =>{
                let userInfo = success;
                subscriptionPayload.user.firstName = userInfo.user.firstName;
                subscriptionPayload.user.lastName = userInfo.user.lastName;
                if(userInfo.entitlements.subscriptionMemberships.length != 0){
                    this.isUserExist = false;
                    for(var i=0; i<userInfo.entitlements.subscriptionMemberships.length; i++){
                        if(userInfo.entitlements.subscriptionMemberships[i].subscription.productId === 'geotax'){
                            if(userInfo.entitlements.subscriptionMemberships[i].subscription.status !== 'CANCELLED'){
                                this.isUserExist = true;
                            }
                            else{
                                this.isUserExist = false;
                            }
                        }
                    }
                    if(!this.isUserExist){
                        this.errMsg = '';
                        this.addToSubscription(subscriptionPayload);
                    }
                    else{
                        this.errMsg = "";
                        this.showLoader = false;
                        this.boldEmail = this.email;

                    }
                }
                else{
                    this.errMsg = '';
                    this.addToSubscription(subscriptionPayload);
                }
            },
            error => {
                this.userService.createUser(userBody).subscribe(
                    success => {
                        this.errMsg = '';
                        subscriptionPayload.user.isUserExisting = false;
                        this.addToSubscription(subscriptionPayload);
                    },
                    error => {
                        if(error.error.message){
                            if(error.error.message.errors[0].errorCode === "PB-APIM-ERR-OKTA-E0000001"){
                                this.errMsg = "Username must be in the form of an email address.";
                            }
                        }
                        else{
                            this.errMsg = "User could not be created. Please try again after some time.";
                        }
                        this.showLoader = false;
                    }
                );
            }
        );
    };
    validateEmail = function() {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.email);
    };
    addToSubscription = function(subscriptionPayload){
        this.userService.addToSubscription(subscriptionPayload).subscribe(
            success => {
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.isCreateSuccess = true;
                this.showLoader = false;
            },
            error => {
                this.errMsg = "User could not be created. Please try again after some time.";
                this.showLoader = false;
            }
        );
    };
}
