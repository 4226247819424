import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    providers: [NgbModalConfig, NgbModal]
})
export class UsersComponent implements OnInit {
  public showpage: boolean = false;
  public usersObj: any;
  public users: any;
  public currentUser: any;
  public tmpDelId:string = '';
  public showDeletePrompt:boolean = false;
  public isActive:boolean = false;
  public userRole: string = '';
  public showLoader: boolean = false;
  @ViewChild('deleteUserModal', { static: false }) deleteUserModalEl: ElementRef;
  constructor(private router : Router, private userService : UserService, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit() {
    let userData = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userData = success;
        if(userData.entitlements[0].status=="ACTIVE"){
          this.isActive=true;
        }
        this.userRole = userData.entitlements[0].role;
        if(this.userRole == 'AdminWithBilling' || this.userRole == 'Admin' || this.userRole == 'FinanceAdmin'){
          this.showpage = true;
        }
        else{
          window.location.href = "/geotax";
        }
        this.showLoader = true;
        this.currentUser = userData.email;
        this.userService.getUsersBySubscriptionId().subscribe(
          success => {
            this.usersObj = success;
            this.users = this.usersObj.items;
            this.showLoader = false;
          }
        );
      },
      error => {
        window.location.href = "/";
      }
    );
  }
  deleteUser = function(userId){
    if(this.isActive){
      this.tmpDelId = userId;
      setTimeout(() =>
        this.modalService.open(this.deleteUserModalEl, { size: 'md', ariaLabelledBy: 'modal-basic-title' })
      );
  }
  };
  confirmDelete = function(){
    (<HTMLElement>document.getElementById('closeDeleteModal')).click();
    this.showLoader = true;
    this.userService.deleteUser(this.tmpDelId).subscribe(
      success => {
        this.showLoader = false;
        for(var i=0; i<this.users.length; i++){
          if(this.users[i].user.id == this.tmpDelId){
            this.users = this.users.slice(0, i).concat(this.users.slice(i+1));
            this.tmpDelId = '';
            break;
          }
        }
      }
    );
  };
}
