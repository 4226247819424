import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GeosearchService } from './geosearch.service';

@Component({
    selector: 'geosearch',
    templateUrl: './geosearch.component.html',
    styleUrls: ['./geosearch.component.css']
})
export class GeosearchComponent {
    public _showResults: boolean = false;
    public loading: boolean = false;
    public queryparms: string;
    public results = {};
    public resultLength: number;
    public selectedIndex: number = -1;
    public requestObj: any = null;
    constructor(private searchLocationsTmp: GeosearchService) { }

    @Input() apiUrl: string;
    @Input() apiKey: string;
    @Input() secret: string;
    @Input() bearerToken: string;
    @Input() country: string;
    @Input() maxCandidates: number;
    @Input() latitude: string;
    @Input() longitude: string;
    @Input() searchRadius: string;
    @Input() searchRadiusUnit: string;
    @Input() charLengthQuery: string;
    @Input() ipAddress: string;
    @Input() autoDetectLocation: string;
    @Input() returnAdminAreasOnly: string;
    @Output() callbackEvent = new EventEmitter<any>();

    onQueryUpdate = function () {
        if (this.maxCandidates === null || this.maxCandidates === undefined || this.maxCandidates === "") {
            this.maxCandidates = 5;
        }
        this.queryparms = '?maxCandidates=' + this.maxCandidates;
        if (this.country !== null && this.country !== undefined && this.country !== "") {
            this.queryparms = this.queryparms + '&country=' + this.country;
        }
        if (this.apiUrl === null || this.apiUrl === undefined || this.apiUrl === "") {
            this.apiUrl = 'https://api.precisely.services/typeahead/v1/locations';
        }
        if (this.latitude !== null && this.latitude !== undefined && this.latitude !== "") {
            this.queryparms = this.queryparms + '&latitude=' + this.latitude;
        }
        if (this.longitude !== null && this.longitude !== undefined && this.longitude !== "") {
            this.queryparms = this.queryparms + '&longitude=' + this.longitude;
        }
        if (this.searchRadius !== null && this.searchRadius !== undefined && this.searchRadius !== "") {
            this.queryparms = this.queryparms + '&searchRadius=' + this.searchRadius;
        }
        if (this.searchRadiusUnit !== null && this.searchRadiusUnit !== undefined && this.searchRadiusUnit !== "") {
            this.queryparms = this.queryparms + '&searchRadius=' + this.searchRadiusUnit;
        }
        if (this.charLengthQuery === null || this.charLengthQuery === undefined && this.charLengthQuery !== "") {
            this.charLengthQuery = 3;
        }
        if (this.ipAddress !== null && this.ipAddress !== undefined && this.ipAddress !== "") {
            this.queryparms = this.queryparms + '&ipAddress=' + this.ipAddress;
        }
        if (this.autoDetectLocation !== null && this.autoDetectLocation !== undefined && this.autoDetectLocation !== "") {
            this.queryparms = this.queryparms + '&autoDetectLocation=' + this.autoDetectLocation;
        }
        if (this.returnAdminAreasOnly !== null && this.returnAdminAreasOnly !== undefined && this.returnAdminAreasOnly !== "") {
            this.queryparms = this.queryparms + '&returnAdminAreasOnly=' + this.returnAdminAreasOnly;
        }
        if (this.query.trim() != "" && this.query.length >= this.charLengthQuery) {
            var _url = this.apiUrl + this.queryparms + '&searchText=' + encodeURIComponent(this.query) + '&clientIdentifier=GeoTAX.com';
            this.loading = true;
            if(this.requestObj != null){
                this.requestObj.unsubscribe();
            }
            this.requestObj = this.searchLocationsTmp.getGeosearchLocations(_url).subscribe(
                success => {
                    this.loading = false;
                    if (success.location) {
                        if (success.location.length != 0) {
                            this.results = success;
                            this.resultLength = success.location.length;
                            this._isError = false;
                            this._showResults = true;
                            this.selectedIndex = 0;
                        }
                        else {
                            this._isError = true;
                            this._showResults = false;
                        }
                    }
                    else {
                        this._isError = true;
                        this._showResults = false;
                    }
                },
                error => {
                    if(error.error.error == 'Cookie tempered'){
                        window.location.href = '/signout';
                        return;
                    }
                    this.loading = false
                    this._isError = true;
                    this._showResults = false;
                }
            );
        }
        if (this.query.length < this.charLengthQuery) {
            this._showResults = false;
        }
    };
    onResultClicked = function (result) {
        if (result.totalUnitCount > 1) {
            this.results.location = [];
            let locArr = [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    //locArr[arrCount].address.addressLastLine = addressArr[1]+', '+addressArr[2];
                    arrCount++;
                }
            }
            this.results.location = locArr;
        }
        else {
            //this.onSelect({result:result});
            this.query = result.address.formattedAddress.replace("<b>", "").replace("</b>", "");
            this._showResults = false;
            this.callbackEvent.emit(result);
        }
    };
    onKeyDown = function (event) {
        if (document.getElementById('geoitemcontainer')) {
            if (event.keyCode === 38) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex > 1) {
                    if (this.addCSS(this.selectedIndex - 1)) {
                        this.selectedIndex = this.selectedIndex - 1;
                        this.removeCSS(indexval);
                        document.getElementById('geoitemcontainer').scrollTop -= 40;
                    }
                }
            }
            if (event.keyCode === 40) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex < this.resultLength) {
                    if (this.addCSS(this.selectedIndex + 1)) {
                        this.selectedIndex = this.selectedIndex + 1;
                        this.removeCSS(indexval);
                        if (this.selectedIndex > 1) {
                            document.getElementById('geoitemcontainer').scrollTop += 40;
                        }
                    }
                }
            }
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('geoitemcontainer').scrollTop = 0;
                var indexval = this.selectedIndex;
                if (indexval > 0) {
                    var result = this.results.location[indexval - 1];
                    if (result.totalUnitCount > 1) {
                        this.results.location = [];
                        var locArr = [];
                        var addrObj = result.address;
                        var geometryObj = result.geometry;
                        var arrCount = 0;
                        this.resultLength = result.totalUnitCount;
                        for (var i = 0; i < result.ranges.length; i++) {
                            for (var j = 0; j < result.ranges[i].units.length; j++) {
                                locArr[arrCount] = {};
                                locArr[arrCount].totalUnitCount = 1;
                                locArr[arrCount].address = Object.assign({}, addrObj);
                                locArr[arrCount].geometry = Object.assign({}, geometryObj);
                                locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                                var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                                locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                                //locArr[arrCount].address.addressLastLine = addressArr[1]+', '+addressArr[2];
                                this.removeCSS(this.selectedIndex);
                                this.selectedIndex = 0;
                                arrCount++;
                            }
                        }
                        this.results.location = locArr;
                    }
                    else {
                        //this.onSelect({result:result});
                        this.removeCSS(this.selectedIndex);
                        this.selectedIndex = 0;
                        this.query = result.address.formattedAddress.replace("<b>", "").replace("</b>", "");
                        this._showResults = false;
                    }
                }
            }
            if (event.keyCode === 27) {
                this.removeCSS(this.selectedIndex);
                this.selectedIndex = 0;
                this._showResults = false;
                event.preventDefault();
            }
        }
    };
    removeCSS = function (index) {
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item";
        }
    };
    addCSS = function (index) {
        var returnval = false;
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item pb-geo-item-selected";
            return true;
        }
        return returnval;
    };
}
