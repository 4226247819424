import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'
import { PbdsDatavizModule } from '@precisely/design-system';
import { PbdsHeaderShadowModule } from '@precisely/design-system';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from './service/user.service';
import { GeosearchService } from './geosearch/geosearch.service';
import { TableModule } from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { NavbarComponent } from './common/navbar/navbar.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AutoComponent } from './auto/auto.component';
import { GeotaxComponent } from './geotax/geotax.component';
import { PricingComponent } from './pricing/pricing.component';
import { GeosearchComponent } from './geosearch/geosearch.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingplansComponent } from './billingplans/billingplans.component';
import { UsersComponent } from './users/users.component';
import { CreateuserComponent } from './createuser/createuser.component';
import { from } from 'rxjs/internal/observable/from';

const CONFIGURE_STATES = [
  { path: '', component: HomeComponent },
  { path: 'auto', component: AutoComponent },
  { path: 'geotax', component: GeotaxComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'myaccount', component: BillingplansComponent },
  { path: 'users', component: UsersComponent },
  { path: 'createuser', component: CreateuserComponent },
  // This is the WILDCARD CATCH-ALL route that is scoped to the entire application.
  // It will catch any request that is not matched by an earlier route definition.
  // Always make sure this is last route
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [AppComponent, NavbarComponent, FooterComponent, HomeComponent, AutoComponent, GeotaxComponent, PricingComponent, GeosearchComponent, BillingplansComponent, UsersComponent, CreateuserComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, PbdsDatavizModule, PbdsHeaderShadowModule, HttpClientModule, RouterModule.forRoot(CONFIGURE_STATES, { relativeLinkResolution: 'legacy' }), NgbModalModule, NgbModule, TableModule, BrowserAnimationsModule, LeafletModule.forRoot()],
  providers: [CookieService, UserService, GeosearchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
