import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()

export class GeosearchService {
    constructor(private http: HttpClient){}

    getGeosearchLocations(_url:any){
        //return this.http.get(_url).pipe(map(r => r.json()));
        return this.http.get(_url);
    }
}