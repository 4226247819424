import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent implements OnInit {
  public showpage:boolean = false;
  public showUpgradeBtn: boolean = false;
  public currentPlan: any = "";
  public hasPastDue: boolean = true;
  public hasFutSubs: boolean = true;
  public isValidEntitlement: boolean = true;
  public activeTab: string = 'monthly';
  public userRole: string = '';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) { };
  ngOnInit() {
    window.scroll(0, 0);
    let userData = null;
    this.userService.getUserDeatails().subscribe(
      success => {
        userData = success;
        this.userRole = userData.entitlements[0].role;
        if(this.userRole == 'AdminWithBilling'){
          this.showpage = true;
        }
        else{
          window.location.href = "/geotax";
        }
        if (userData.entitlements.length > 0) {
          if (userData.entitlements[0].product === "geotax") {
            if(userData.entitlements[0].status == 'SUSPENDED' || userData.entitlements[0].status == 'CANCELLED'){
              this.isValidEntitlement = false;
            }
            this.currentPlan = userData.entitlements[0].plan;
            let recai = userData.entitlements[0].recai;
            if(recai){
              let planId = this.currentPlan;
              if (planId == 'GT_General_Auto_Basic_Monthly' || planId == 'GT_General_Auto_100_Monthly' || planId == 'GT_General_Auto_100_Annual') {
                this.getUserAccountDetails();
              }
              else{
                this.hasPastDue = false;
                this.hasFutSubs = false;
                this.checkAccount();
              }
            }
            else{
              alert("User without recurly account id.");
            }
          }
        }
        if (this.currentPlan !== "") {
          this.showUpgradeBtn = true;
        }
      },
      error => {
        window.location.href = "/";
      }
    );
  };
  checkAccount= function(){
    this.userService.getUserAccountDetailsById().subscribe(
      success => {},
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if(success.data.length > 0){
          this.hasPastDue = success.data[0].has_past_due_invoice;
          this.hasFutSubs = success.data[0].has_future_subscription;
        }
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
        }
      }
    );
  };
  upgradePlan = function (planName) {
    var upgradePlanURL = localStorage.getItem("signUpURL") + "/upgrade/geotax?plan=" + planName;
    window.location.href = upgradePlanURL;
  };
}
